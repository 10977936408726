import "babel-polyfill";
import "react-app-polyfill/ie11";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "./index.scss";
import * as serviceWorker from "@ec-oem/ec.oem.oa3.ui.common/utility/registerServiceWorker";
import { getStore } from "./app/common/utility/store";
import { initializeIcons } from "@uifabric/icons";
import { localizationData } from "./resources/localization/localizationData";
import ApplicationConfig from "@ec-oem/ec.oem.oa3.ui.common/utility/applicationConfig";
import { AzureAD } from "@ec-oem/ec.oem.oa3.ui.core/components/Auth";
import { LanguageProvider } from "@ec-oem/ec.oem.oa3.ui.common/components/LanguageProvider";
import { ProgressIndicator } from "office-ui-fabric-react";
import { ErrorBoundary } from "@ec-oem/ec.oem.oa3.ui.common/components";

const App = React.lazy(() => import("./app/App"));

initializeIcons("https://res.cdn.office.net/files/fabric-cdn-prod_20241209.001/assets/icons/");

ApplicationConfig.config.then((config) => {
	const store = getStore();
	ReactDOM.render(
		<Provider store={store}>
			<Suspense fallback={<ProgressIndicator />}>
				<LanguageProvider localization={localizationData}>
					<ErrorBoundary>
						<AzureAD msal_config={config.MSAL_info} componentToRender={App} />
					</ErrorBoundary>
				</LanguageProvider>
			</Suspense>
		</Provider>,
		document.getElementById("root")
	);

	serviceWorker.register();
	return config;
});
